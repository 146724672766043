import React from 'react';
import { useAppSelector } from '@app/hooks';
import { ObjectModel } from '@types';
import { selectCurrentProduct } from '@modules/quotation';
import { ModelDetails } from './model-details';
import { NonCadDetails } from './non-cad-details';
import { ProductDetails } from './product-details';

interface ModelDetailsHandlerProps {
    model: ObjectModel;
}

export const DetailsHandler: React.FC<ModelDetailsHandlerProps> = ({ model, children }) => {
    const currentProduct = useAppSelector(selectCurrentProduct);

    if (model.is_processable) {
        return currentProduct ? <ProductDetails model={model} /> : <ModelDetails model={model} />;
    }

    return <NonCadDetails model={model} />;
};
