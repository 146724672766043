import cn from 'classnames';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Divider } from '@react-md/divider';
import { withBaseErrorBoundary } from '@components/error-boundaries';
import { BackLink } from '@components/link';
import { Fallback } from '@components/fallback';
import { PreloaderBox } from '@components/preloader';
import { selectAppSettings } from '@modules/app';
import { orderPageActions, selectOrderPageData, selectIsOrderPageLoading } from '@modules/order-page';
import { useBuildLocation } from '@hooks';
import { composeFullAddress, composeNodeId, reverse } from '@utils';
import { DeliveryType } from '@types';
import { OrderPageHeader, OrderPageParts, OrderPageDelivery, OrderPageNotes, OrderPageSidebar } from './components';
import { RootPrefix } from './constants';
import { useGetResizeRef } from '@utils/parant-site-comunicator';

import styles from './order-page.module.scss';

const Page = () => {
    const { orderId } = useParams();

    const dispatch = useAppDispatch();
    const settings = useAppSelector(selectAppSettings);
    const order = useAppSelector(selectOrderPageData);
    const isLoading = useAppSelector(selectIsOrderPageLoading);
    const { t } = useTranslation();

    const containerRef = useGetResizeRef();

    const prevLocation = useBuildLocation({
        to: reverse('userOrders'),
    });

    useEffect(() => {
        dispatch(orderPageActions.load(orderId!));
    }, [orderId, dispatch]);

    if (!order) {
        return <Fallback />;
    }

    return (
        <div id={RootPrefix} className={styles.layout} ref={containerRef}>
            {/*todo use PageLayout*/}

            <div className="container">
                <BackLink id={composeNodeId(RootPrefix, 'back', 'link')} to={prevLocation.to} />
                <PreloaderBox show={isLoading} fade>
                    <div className={styles.grid}>
                        <div className={cn('rounded-box', styles.left)}>
                            <OrderPageHeader
                                id={order.company_order_id}
                                orderDate={order.date}
                                totalPrice={order.total_price}
                                trackingNumber={order.tracking_number}
                                status={order.status}
                                statusDisplay={order.status_display}
                            />
                            <OrderPageParts order={order} />
                            <Divider />
                            <OrderPageDelivery
                                deliveryTypeTitle={order.delivery_type_title}
                                pickupAddress={
                                    order.delivery_type === DeliveryType.NotSpecifiedDelivery
                                        ? composeFullAddress(settings.company_address)
                                        : ''
                                }
                                shippingAddress={
                                    order.shipping_address ? composeFullAddress(order.shipping_address) : ''
                                }
                            />
                            {(order.notes || order.attached_file) && (
                                <>
                                    <Divider />
                                    <OrderPageNotes notes={order.notes} file={order.attached_file} />
                                </>
                            )}
                        </div>

                        <OrderPageSidebar order={order} />
                    </div>
                </PreloaderBox>
            </div>
        </div>
    );
};

export const UserOrderPage = withBaseErrorBoundary(Page);
