import { useCallback, useRef } from 'react';
import { useResizeObserver } from '@react-md/utils';
import { setWidgetIframeHeight } from './set-widget-iframe-height';

export function useGetResizeRef() {
    const onResize = useCallback(({ height }) => {
        const headersHeight = Number(
            document.documentElement.style.getPropertyValue('--p-header-height').replace('px', ''),
        );
        // ** appPadding must be equal or less than $app-padding or order page will increase the height **
        const appPadding = 20;
        const widgetHeight = height + headersHeight + appPadding;
        setWidgetIframeHeight(widgetHeight);
    }, []);

    const ref = useRef<HTMLDivElement>(null);
    const [, refHandler] = useResizeObserver(onResize, {
        ref,
        disableWidth: true,
    });

    return refHandler;
}
