import { BillingAddress, DeliveryAddress } from './address';
import { ClientRepresentationSerializer } from './client';
import { AppliedDiscount } from './discount';
import { GetRequestBaseParams } from './request';
import { InvoiceBase } from './invoice';
import { Product } from './product';
import { Purchase } from './purchase';
import { ResponseErrors } from './response';
import { TBD } from './tbd';
import { UseFormGetFieldState, UseFormWatch } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

export enum OrderStatuses {
    Initial = 'initial',
    Placed = 'placed',
    Created = 'created',
    FirmOfferSent = 'firm_offer_sent',
    WaitingForReview = 'waiting_for_review',
    CancelledByAdmin = 'cancelled_by_admin',
    CancelledByUser = 'cancelled_by_user',
    PoProvided = 'po_provided',
    PoPaymentDue = 'po_payment_due',
}

export type AvailableInitialOrderStatuses = Array<`${OrderStatuses}`>;

// InitialOrder replaces the Cart entity
export interface InitialOrder extends TBD {
    id: number;
    delivery_time: number;
    delivery_price?: string;
    locale: string;
    upload_job?: string;
    models_naked_price: number;
    models_price: string;
    order_discount_in_percents: string;
    order_discount_value: string;
    price_corrections: string;
    price_min_difference: string;
    product_count: number;
    products: Product[];
    products_discount_value: string;
    startup_cost: string;
    tax_in_percents: number;
    total_discount_value: string;
    total_price: string;
    total_price_without_tax: string;
    use_additional_startup_cost: boolean;
    available_order_initial_statuses?: AvailableInitialOrderStatuses;
    applied_discount: AppliedDiscount | null;
    buyer_id: number;
    currency: string;
    status: OrderStatuses;
    custom_rate?: null | number; // CompanyCustomShipping
    easypost_rate?: null | string; // EasyPostRate
    company_order_id?: number;
    pdf_url: string;
}

export interface InitialOrderCreateRequest {
    upload_job?: string;
    payload?: unknown;
}

// export type UpdateOrderRatePayload =
//     | { rate_id: number; custom_shipping: true }
//     | { rate_id: string; custom_shipping: false }
//     | { rate_id: 'null'; custom_shipping: boolean };
export type UpdateOrderRatePayload = {
    rate_id?: number | string | 'null';
    // custom_shipping?: boolean;
    delivery_type?: DeliveryType;
};

export enum DeliveryType {
    NotSpecifiedDelivery = 'not_set',
    PickUp = 'pick_up',
    Delivery = 'delivery',
    FlatFee = 'flat_fee',
    EasyPost = 'ep',
    // Custom = 'custom',
}

export const InitialOrderDeliveryTypes = {
    '-1': DeliveryType.NotSpecifiedDelivery,
    '0': DeliveryType.PickUp,
    '1': DeliveryType.Delivery,
};

export type DeliveryTypeVariant = (typeof InitialOrderDeliveryTypes)[keyof typeof InitialOrderDeliveryTypes];

export type UpdateOrderDeliveryType = { delivery_type: DeliveryTypeVariant };

export type InitialOrderUpdateRequest =
    | {
          use_additional_startup_cost: boolean;
      }
    | UpdateOrderRatePayload
    | UpdateOrderDeliveryType;

export interface OrderPriceCorrection {
    id: number;
    value: number;
    comment?: string;
    // date_created: string;
    // type: 'general' | 'delivery';
}

export type OrderPriceCorrectionForm = Pick<OrderPriceCorrection, 'comment' | 'value'>;

export interface Order extends TBD {
    id: number;
    name: string;
    surname: string;
    phone: string;
    email: string;
    billing_name: string;
    billing_surname: string;
    billing_phone: string;
    billing_email: string;
    vat_number?: string;
    can_repeat_order?: boolean;
    client?: null | ClientRepresentationSerializer;

    additional_billing_contacts?: string | string[];
    billing_address?: BillingAddress | null;
    shipping_address?: DeliveryAddress | null;
    // delivery_address?: DeliveryAddress[] | null;

    tax_in_percents: string;
    tax_value: number;
    order_discount_in_percents: string;
    order_discount_value: number;
    products_discount_value: number;
    total_discount_value: number;
    models_naked_price: number;
    startup_cost: number;
    models_price: number;
    price_corrections_cost: string;
    total_price: number;
    total_price_without_tax: number;
    product_count: number;
    status_display: string;
    quote_expiration_date: string;
    company_order_id?: number;
    po_number?: string;
    attached_po?: string | null;
    notes?: string;
    attached_file?: string | null;
    date: string;
    updated_at: string;
    status: OrderStatuses;
    locale: string;
    delivery_title?: string;
    delivery_type_title: string;
    delivery_type: DeliveryType;
    delivery_taxable: boolean;
    delivery_price?: number;
    delivery_time: number;
    estimated_shipment_date?: string | null;
    ep_rate_id?: string;
    ep_shipping_label?: string;
    xero_invoice_id?: string;
    salesforce_quote_id?: string;
    salesforce_opportunity_id?: string;
    tax?: number;
    currency: string;
    startup_cost_override?: null | number;
    discount_rate?: number;
    tracking_number?: string;
    dynamic_tax_rates?: string; // object
    self_notes?: string;
    payload?: null | string;
    inhouse_owner?: null | unknown;
    custom_rate?: null | number; // CompanyCustomShipping
    easypost_rate?: null | string; // EasyPostRate
    applied_discount?: AppliedDiscount | null;
    additional_data: null | number; // OrderAdditionalData
    line_items: Purchase[];
    main_invoice?: Pick<InvoiceBase, 'id' | 'order' | 'is_paid' | 'payment_received' | 'cost' | 'pay_url'>;
    price_corrections: OrderPriceCorrection[];
    remaining_amount: string;
    down_payment_amount?: number;
    down_payment_rate?: number;
    is_down_payment_shown?: boolean;
    is_remaining_amount_shown?: boolean;
}

export interface OrderCreateRequest {
    cart_id: number;
    name: string;
    surname: string;
    phone: string;
    email: string;
    billing_name?: string;
    billing_surname?: string;
    billing_phone?: string;
    billing_email?: string;
    notes?: string;
    attached_file?: File;
    status: OrderStatuses;
    customer_company_address?: string; // OrderBillingAddressInfoDataSerializer
    delivery_address?: string; // OrderAddressInfoDataSerializer
    custom_fields?: string[]; // CustomFieldsSerializer
}

export interface OrderCreateResponse {
    id: number;
    pay_url: string;
    order_url: string;
    invoice_id: string;
    invoice_hash: string;
    company_order_id: string;
}

export type OrderFormErrors = ResponseErrors<OrderCreateRequest>;

export type PromocodeForm = {
    code: string;
};

export enum ShippingTypes {
    EmptyByDefault = -1,
    Pickup = 0,
    Delivery = 1,
}

export interface CustomFieldData {
    key: string;
    value: string;
    label: string | undefined;
}

export interface OrderForm {
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
    billing_name: string;
    billing_surname: string;
    billing_phone: string;
    billing_email: string;
    billing_address: BillingAddress;
    delivery_address: DeliveryAddress;
    notes?: string;

    shippingRateId: string;
    status?: string;

    shippingType?: ShippingTypes;
    isCompanyOrder: boolean;
    custom_fields?: { [key: string]: string };
}

export interface LoadOrdersParams extends GetRequestBaseParams {
    // filtering
    status__in?: string;
    status__not__in?: string;

    // sorting
    ordering?: string; // 'date' | '-date'
}

export enum FilterOrderStatus {
    All = 'all',
    Placed = 'placed',
    Quotes = 'quotes',
    Abandoned = 'abandoned',
}

export type FilterOrderStatusKeys = `${FilterOrderStatus}`;

export interface FormAttributes {
    errors: UseFormGetFieldState<FieldValues> | null;
    watch: UseFormWatch<FieldValues> | null;
}
