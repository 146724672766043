import cn from 'classnames';
import React from 'react';
import { ObjectModel } from '@types';
import { ModelSizes, ModelVolume } from '@modules/models';
import { useAppSelector } from '@app/hooks';
import { selectAppSettings } from '@modules/app';

interface Props {
    model: ObjectModel;
}

export const ProductDetails: React.FC<Props> = ({ model }) => {
    const settings = useAppSelector(selectAppSettings);
    const defaultConvertedUnits = settings.display_widget_units || model.units;

    return (
        <div className={cn('fade-in', 'margin-top')}>
            <ModelSizes
                size={model.size}
                units={model.units}
                convertTo={defaultConvertedUnits}
                className="justify-center"
            />
            <ModelVolume model={model} convertTo={defaultConvertedUnits} className="justify-center" />
        </div>
    );
};
