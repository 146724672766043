import React from 'react';
import { useTranslation } from 'react-i18next';
import { ObjectModel } from '@types';
import { getFileExtensionByUrl } from '@utils';
import { LineItem } from '@components/line-items';
import { DetailsBox } from './details-box';
import { NonCadSizes } from './non-cad-sizes';
import { nonCadDetailsId } from './helpers';

import styles from './non-cad-details.module.scss';

interface Props {
    model: ObjectModel;
}

export const NonCadDetails: React.FC<Props> = ({ model }) => {
    const fileExtension = getFileExtensionByUrl(model.title); // TODO model.extension?
    const { t } = useTranslation();
    return (
        <DetailsBox className={styles.box}>
            <NonCadSizes model={model} />
            <LineItem label={t('labels.format', 'Format')} value={fileExtension} />
        </DetailsBox>
    );
};
