import {
    Point,
    ObjectModel,
    ObjectModelThumbStatus,
    ObjectModelUnits,
    ObjectModelGeometryType,
    ObjectModelCncFeature,
} from '@types';

export class ObjectModelModel implements ObjectModel {
    public isModelRendered: boolean;

    static readonly usedFields: Array<keyof ObjectModel> = [
        'file_model_viewer_url',
        'parent_model',
        'id',
        'title',
        'size',
        'volume',
        'units',
        'is_processable',
        'geometry_type',
        'thumb',
        'thumb_120x120',
        'thumb_300x300',
        'thumb_status',
        'cnc_features',
        'child_models',
    ];

    static from(data: Record<string, any>) {
        return new ObjectModelModel(
            data.id,
            data.parent_model,
            data.child_models,
            data.file_model_viewer_url,
            data.object_model_original_url,
            data.title,
            data.size,
            data.volume,
            data.units,
            data.is_processable,
            data.geometry_type,
            data.thumb,
            data.thumb_120x120,
            data.thumb_300x300,
            data.thumb_status,
            data.cnc_features,
        );
    }

    constructor(
        public id: number,
        public parent_model: number,
        public child_models: number[],
        public file_model_viewer_url: string,
        public object_model_original_url: string,
        public title: string,
        public size: Point,
        public volume: number,
        public units: ObjectModelUnits,
        public is_processable: boolean,
        public geometry_type: ObjectModelGeometryType,
        public thumb: string,
        public thumb_120x120: string,
        public thumb_300x300: string,
        public thumb_status: ObjectModelThumbStatus,
        public cnc_features: ObjectModelCncFeature[],
    ) {
        // This var needs for the first time render of 3D-model by 3D-viewer
        this.isModelRendered = false;
    }

    get shouldCheckWallThickness() {
        return this.is_processable && this.geometry_type === 'other';
    }

    get allowToRenderViewer() {
        return (
            this.is_processable &&
            Boolean(this.file_model_viewer_url) &&
            this.file_model_viewer_url.includes('amazonaws.com')
        );
    }

    setIsModelRendered(state: boolean) {
        this.isModelRendered = state;
    }
}
