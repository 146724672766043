import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TransitionGroup } from 'react-transition-group';
import { Divider } from '@react-md/divider';
import { Typography } from '@react-md/typography';
import { Icons } from '@components/icons';
import { Label } from '@components/label';
import { FADE_STAGGER } from '@components/animations';
import { FeatureContent } from '@components/feature-content';
import { Order } from '@types';
import { partsId } from '../helpers';
import { InvoicePart } from './invoice-part';
import { InvoiceTileHead } from '@components/invoice-title-head';

import styles from './invoice-parts.module.scss';

interface InvoicePartsProps {
    order: Order;
    className?: string;
}

export const InvoiceParts: React.FC<InvoicePartsProps> = ({ className, children, order }) => {
    const { t } = useTranslation();

    const { company_order_id: orderId, status_display: statusDisplay } = order;

    return (
        <div className={cn('rounded-box', className)}>
            {orderId && (
                <>
                    <div className={cn('base-paddings', styles.header)}>
                        <Typography className="invoice-no-icon-gap" type="headline-3">
                            <span>{t('titles.order', 'Order #{{orderId}}', { orderId })}</span>
                        </Typography>
                        <Typography className="invoice-no-icon-gap" type="body-2">
                            <b>Status:</b> <span>{statusDisplay}</span>
                        </Typography>
                    </div>
                    <Divider />
                </>
            )}

            <InvoiceTileHead
                id={partsId('title')}
                className={cn('base-paddings', { 'no-top': Boolean(orderId) })}
                icon={Icons.Box}
            >
                <FeatureContent contentKey={partsId('title')} fallback={t('titles.parts', 'Parts')} />
            </InvoiceTileHead>

            <div className={cn('overflow-x-auto', styles.table)}>
                <table>
                    <thead className={styles.head}>
                        <tr>
                            <td className={cn(styles.cell, styles.counter)} />

                            <td className={styles.cell}>
                                <Label className={cn('small-margin-bottom', styles.label)}>
                                    {t('labels.productModel', 'Model')}
                                </Label>
                            </td>
                            <td className={styles.cell}>
                                <Label className={cn('small-margin-bottom', styles.label)}>
                                    {t('labels.material', 'Material')}
                                </Label>
                            </td>
                            <td className={styles.cell}>
                                <Label className={cn('small-margin-bottom', styles.label)}>
                                    {t('labels.productSpecification', 'Specification')}
                                </Label>
                            </td>
                            <td className={styles.cell}>
                                <Label className={cn('small-margin-bottom', styles.label, 'rmd-typography--right')}>
                                    {t('labels.discount', 'Discount')}
                                </Label>
                            </td>
                            <td className={styles.cell}>
                                <Label className={cn('small-margin-bottom', styles.label, 'rmd-typography--right')}>
                                    {t('labels.productPricePerPart', 'Price per part')}
                                </Label>
                            </td>
                            <td className={styles.cell}>
                                <Label className={cn('small-margin-bottom', styles.label, 'rmd-typography--right')}>
                                    {t('labels.quantity', 'Quantity')}
                                </Label>
                            </td>
                            <td className={styles.cell}>
                                <Label className={cn('small-margin-bottom', styles.label, 'rmd-typography--right')}>
                                    {t('labels.total', 'Total')}
                                </Label>
                            </td>
                        </tr>
                    </thead>

                    <TransitionGroup className={styles.body} component="tbody">
                        {order.line_items.map((purchase, i) => (
                            <InvoicePart key={purchase.id} purchase={purchase} delay={FADE_STAGGER * i} />
                        ))}
                    </TransitionGroup>
                </table>
            </div>

            {children}
        </div>
    );
};
